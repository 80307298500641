:root {
	--primaryColor: #118199;
	--secondaryColor: #F1F8F9;
	--secondarySelectedTextColor: #000;
	--whiteColor: #fff;
	--blackColor: #000;
	--errorColor: #CF3022;
	--linkColor: #226DDF;
}
.my-color {
	color: --linkColor;
}

.my-font {
	font-family: 'Work Sans';
}

.my-root-class {
	background: none;
}


div.kc-logo-text {
	background-image: url(./assets/logo.png);
	background-repeat: no-repeat;
	height: 78px;
	width: 78px;
	margin: 0 auto;
	background-size: auto 100%;
	background-position: center;
} 
  
.login-pf body {
	background-image: url(assets/background.jpg);
	background-size: cover;
  	height: 100%;
}

.card-pf {
	margin: 0 auto;
	box-shadow: var(--pf-global--BoxShadow--lg);
	padding: 0 20px;
	max-width: 450px;
	border-top: 4px solid;
	border-color: var(--whiteColor);
	margin-top: 0;
	border-radius: 10px;
	box-shadow: 0px 12px 24px 0px #1018281A;
}

.login-pf-page .login-pf-accounts {
	max-width: 450px;
}


.heading-wrapper {
	margin-bottom: 20px;
}

.heading-wrapper h1.title {
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	text-align: center;
	letter-spacing: 0.02em;
	color: var(--primaryColor);
}

.title-wrapper {
	display: flex;
	align-items: center !important;
	justify-content: space-between;
	flex-direction: row !important;
}

.title-wrapper h2 {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	color: var(--primaryColor);
}

h1#kc-page-title {
	margin-top: 10px;
}

.form-group {
	position: relative;
}

#kc-form-login .form-group .form-label {
	display: inline-block;
	margin-bottom: 5px;
	color: var(--blackColor);
	font-size: 15px;
}

input[type="text"],
input[type="password"],
input[type="email"] {
	background-image: none !important;
}

/* language dropdown */
#kc-locale:hover ul {
	display: none;
}

#kc-locale ul {
	background-color: var(--whiteColor);
	display: none;
	top: auto !important;
	min-width: 100px;
	padding: 0;
	height: 350px;
}

#kc-locale.activated ul {
	display: block;
}

/* IE compatibility */
#kc-locale::after {
	content: "";
	border-color: #000 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0px 4px;
	left: 75%;
	margin-left: 5px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

#kc-locale.activated::after {
	content: "";
	border-color: transparent transparent #000 transparent;
	border-style: solid;
	border-width: 0 4px 5px 4px;
	left: 75%;
	margin-left: 5px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.kc-dropdown {
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.5);
	border: 1px solid #C4C4C4;
	border-radius: 25px;
	width: 115px;
	height: 40px;
	text-align: center;

}

.kc-dropdown a,
.kc-dropdown a:active,
.kc-dropdown a:visited,
.kc-dropdown a:hover,
.kc-dropdown label {
	font-weight: 600 !important;
	font-size: 16px;
	color: var(--blackColor);
	line-height: 40px;
	padding-right: 10px;
	text-decoration: none;
	width: 90px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.kc-dropdown-list {
	overflow-y: auto;
	list-style: none;
	padding: 15px !important;
	margin-left: -180px;
	box-sizing: border-box;
	width: 250px;
	border: 0;
	box-shadow: 0px 2px 8px -1px rgb(0 0 0 / 30%);
	border-radius: 10px;
}

.kc-dropdown-item {
	cursor: pointer;
	text-align: left;
	width: 100%;
	display: inline-block;
}

.kc-dropdown-item a,
.kc-dropdown-item a:active,
.kc-dropdown-item a:hover {
	font-weight: 600;
	font-size: 15px;
	color: var(--blackColor);
	line-height: 18px;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	padding: 21px 15px !important;
}

.kc-dropdown-item:hover,
.kc-dropdown-item.selected {
	background: var(--secondaryColor);
	border-radius: 5px !important;
	color: var(--secondarySelectedTextColor) !important;
}

.kc-dropdown-item a:hover,
#kc-locale ul li a:hover,
.kc-dropdown-item.selected a,
#kc-locale ul li.selected a  {
	color: var(--secondarySelectedTextColor) !important;
}
  
.kc-dropdown-item.selected::before{
	content: "";
	position: absolute;
	width: 5px;
	height: 60px;
	left: -179px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 5px 0px 0px 5px;
	transform: matrix(-1, 0, 0, 1, 0, 0);
}

a#kc-current-locale-link::after {
	content: none;
}

#kc-locale ul li a:hover {
	background-color: transparent;
}
  input::placeholder {
	opacity: 5;
  }
  
  .title-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: inherit;
  }
  
  .title {
	font-size: 1.2em;
	color: var(--blackColor);
  }
  
  .sub-title {
	font-size: 1em;
	font-weight: lighter;
	color: var(--blackColor);
  }
  
  .login-pf-page .card-pf {
	border-radius: 10px;
	background: white;
	border: none;
	box-shadow: 0 0.2em 0.4em #00000033;
  }
  
  /* Card Title */
  
  #kc-form-login {
	width: auto;
	margin: auto;
  }
  
  #kc-page-title {
	font-size: 1.4em;
	text-transform: uppercase;
	font-weight: 600;
	color: grey;
  }
  
  #kc-form-subtitle {
	margin: 0.5em 0;
	color: #a5a5a5;
	font-size: 0.9em;
  }

.form-group label {
	display: inline-block;
	margin-bottom: 5px;
	color: var(--blackColor);
	font-size: 14px;
	font-weight: 500;
  }
  
  #username,
  #password,
  #password-new,
  #email,
  #firstName,
  #lastName,
  #password-confirm {
	padding: 12px 16px 12px 16px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #344054;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
  }
  
  #password {
	padding-right: 35px !important;
  }

  #username.alert-error,
  #password.alert-error,
  #email.alert-error,
  #firstName.alert-error,
  #lastName.alert-error,
  #password-confirm.alert-error
   {
	border: 1px solid var(--errorColor);
	width: 100%;
  }

  .kcFormOptionsWrapperClass a {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	color: #226DDF;
  }
  
  #kc-social-providers {
	display: block;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
  }
  
  #kc-content-wrapper {
	margin: 0;
  }
  
  #kc-social-providers h4,
  #kc-registration h4 {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #000000;
	width: 100%; 
	border-bottom: 1px solid #EAECF0; 
	line-height: 0.1em;
	margin: 10px 0 20px; 
  }
  
  #kc-social-providers h4 span,
  #kc-registration h4 span {
	background:var(--whiteColor); 
	padding:0 10px; 
  }

  #kc-registration a {
	font-size: 16px;
	display: inline-block;
	color: #000;
	padding-top: 50px;
  }
  
  #social-ul {
	display: table;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
  }
  
  .social-block {
	box-sizing: border-box;
	padding: 12px 10px;
	line-height: 12px;
	gap: 12px;
	width: 48px;
	height: 48px;
	background: var(--whiteColor);
	border: 1px solid #D0D5DD;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	display: inline-block;
	margin: 20px 7px;
  }

  .social-li:nth-child(1) {
	margin-left: 0px !important;
  }
  
  .social-li a span {
	padding-top: 20px;
	display: block;
	font-size: 12px;
	line-height: 18px;
	color: #000;
	width: 50px;
	margin-left: -12px;
  }
  
  .kc-logo-text {
	height: 1em;
	width: 1em;
	background-size: cover;
  }
  
  .social-li > a:hover {
	color: black;
	text-decoration: none;
  }
  
  .social-saml {
	color: #333;
	padding: 0.6em 1em;
	display: flex;
	font-size: 0.8em;
	text-decoration: none;
	text-transform: capitalize;
  }
  
  /* Login Form */
  
  .login-pf-page .form-control {
	border: 1px solid grey;
	font-size: 0.9em;
	color: grey;
	height: auto;
	background: white;
  }
  
  /* Login Button */
  .form-group {
	margin-bottom: 15px;
  }
  
  #kc-form-button {
	margin: 0;
  }
  

  
  .spacer {
	margin-bottom: 50px;
	height: 20px;
  }
  
  .txtCenter {
	text-align: center;
  }
  
  .recoverContainer {
	padding-top: 20px;
	text-align: center;
  }
  
  .iconWrapper {
	padding: 15px;
	border-radius: 15px;
	background-color: rgba(34, 109, 223, 0.15);
	width: 65px;
	height: 65px;
	display: inline-block;
  }
  
  .recoverPasswordTitle {
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	color: #000;
	display: inline-block;
	padding-left: 20px;
	vertical-align: top;
	padding-top: 20px;
    letter-spacing: 1px;
  }
  
  .recoverContent {
	margin-top: 20px;
	margin-top: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .recoverContent span {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #000000;
	text-align: center;
  }
  
  .recoverContent span.recoverHighlight {
	color: #1C3E71 !important;
  }
  
  .recoverBackButton {
	margin-top: 30px;
  }
  

  #kc-header-wrapper {
	padding-top: 0px;
  }

  .login-pf-page .login-pf-page-header {
	margin-bottom: 0px;
  }
  
  /* small scale */
  @media (max-width: 767px) {
	#kc-locale {
		margin-top: 5px;
		position: relative;
		text-align: right;
		z-index: 9999;
		width: auto;
		right: 0;
		top: 0;
	}
	#kc-locale ul {
	  height: 270px;
	}
	.pf-c-alert__icon, .kc-feedback-text {
	  padding-top: 5px;
	  padding-bottom: 5px;
	}
	.login-pf-page .card-pf {
		max-width: none;
		padding-top: 0;
		border-top: 0;
		box-shadow: 0 0;
		margin-right: 15px;
		margin-left: 15px;
	}
	#kc-header-wrapper {
		padding: 0px;
	}
	.login-pf-page .login-pf-page-header {
		margin-bottom: 20px;
	}
  }

  .show-pass {
	position: absolute;
	top: 35px;
	right: 5px;
	border: 0;
	opacity: 0.8;
	border-radius: 7px;
	box-shadow: none;
	background-image: url("./assets/icon-pass.png");
	background-position: center center;
	background-repeat: no-repeat;
	width: 32px;
	height: 32px;
	background-color: transparent;
	z-index: 10;
  }
  
  .hide-pass {
	position: absolute;
	top: 35px;
	right: 5px;
	border: 0;
	opacity: 0.8;
	border-radius: 7px;
	box-shadow: none;
	background-image: url("./assets/icon-pass-visible.png");
	background-position: center center;
	background-repeat: no-repeat;
	width: 32px;
	height: 32px;
	background-color: transparent;
	z-index: 10;
  }

  .show-pass.register, 
  .hide-pass.register {
	right: 25px !important;
    top: 8px !important;
  }
  
  .show-pass:hover,
  .show-pass:focus {
	opacity: 1;
  }

  .alert-error {
	width: 60%;
	color: var(--errorColor);
	border: none;
	font-size: 14px;
    line-height: 20px;
	padding-left: 0px;
	padding-top: 0px;
  }

  .alert-warning {
	border-radius: 5px;
	font-size: 14px;
  }

  .alert-info {
	border-radius: 5px;
	font-size: 14px;
    border: none;
  }

  .alert {
	padding-left: 0px;
	padding-top: 0px;
  }

  .social-li {
	display: inline-flex !important;
  }

  .social-li a img {
	display: block;
	width: 25px;
    height: 25px;
  }

  .icon-social-provider {
	content:url("./assets/icons/login-icon.png");
  }

  .icon-microsoft {
	content:url("./assets/icons/microsoft.png") !important;
  }

  .icon-google {
	content:url("./assets/icons/google.png") !important;
  }

  .icon-facebook {
	content:url("./assets/icons/facebook.png") !important;
  }

  .icon-bitbucket {
	content:url("./assets/icons/bitbucket.png") !important;
  }

  .icon-github {
	content:url("./assets/icons/github.png") !important;
  }

  .icon-gitlab {
	content:url("./assets/icons/gitlab.png") !important;
  }

  .icon-instagram {
	content:url("./assets/icons/instagram.png") !important;
  }

  .icon-linkedin {
	content:url("./assets/icons/linkedin.png") !important;
  }

  .icon-paypal {
	content:url("./assets/icons/paypal.png") !important;
  }

  .icon-stackoverflow {
	content:url("./assets/icons/stackoverflow.png") !important;
  }

.btn-primary {
	background: var(--primaryColor);
	color: var(--whiteColor);
}

.btn-primary:hover,
.btn-primary:active {
	background: var(--primaryColor);
	color: var(--whiteColor);
}

.btn-primary:focus,
.btn-primary:active:focus {
	background: var(--primaryColor);
	outline: none;
}

.btn-primary {
	width: 85%;
	height: 48px;
	padding: 0.8em;
	margin: 0 auto;
	color: var(--whiteColor);
	text-decoration: none;
	font-size: 16px !important;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	margin-bottom: 25px;
}

input.btn-primary[type="submit"]:disabled {
	background: linear-gradient(0deg, #EAECF0, #EAECF0), #EAECF0 !important;
	cursor: not-allowed !important;
}

.login-pf-page .login-pf-signup {
	margin-top: 0px;
}

#kc-form-options span {
	text-align: center;
}

#kc-reset-password-form .kcFormGroupClass {
	margin-left: 0px;
	margin-right: 0px;
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
	width: 85%;
}

.icon-pass-reset {
	width: 104px;
	height: 104px;
	content:url("./assets/icon-pass-reset.png");
}

#kc-form-buttons {
	margin-top: 20px;
}
